import React, { useContext, useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RingSpinner } from "Components/RingSpinner";
import { RegistrationForm } from "Components/Form";
import { useMediaQuery } from "hooks";
import { useTrail, a } from "@react-spring/web";
import chevron from "Components/Assets/chevron.png";
import Aos from "aos";
import "aos/dist/aos.css";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [openModal, setOpenModal] = useState(false);
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(false);

    const [open, set] = useState(true);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;

    const isMobile = useMediaQuery();

    // logo
    const { brand: brandLogo, hero } = event.logos;

    if (registered) formStep = event.register.sweeps.length - 1;
    // registration content
    const {
      heading,
      subHeading,
      body,
      asideImage,
      artistImageMob,
      formHeading,
      formLegal,
      submit,
    } = event.register.sweeps[formStep];

    let asideArtistImg = `${api}/${storageDirectory}/${asideImage}`;
    let asideArtistImgMobile = `${api}/${storageDirectory}/${artistImageMob}`;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event.callToActions.musicVideo;
    let videoBgDesktop = `${api}/${storageDirectory}/${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/${storageDirectory}/${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event.callToActions.brand;

    // artist
    const {
      imageCTA,
      imageCTAMob,
      heroImg,
      heroImgMob,
    } = event.callToActions.images;
    let imgCTA = `${api}/${storageDirectory}/${imageCTA}?alt=media`;
    let imgCTAMobile = `${api}/${storageDirectory}/${imageCTAMob}?alt=media`;
    let imgHero = `${api}/${storageDirectory}/${heroImg}?alt=media`;
    let imgHeroMobile = `${api}/${storageDirectory}/${heroImgMob}?alt=media`;

    // artist mobile
    const {
      img1,
      img2,
      img3,
      img4,
      img5,
    } = event.callToActions.artistImagesMobile;
    let imgMob1 = `${api}/${storageDirectory}/${img1}?alt=media`;
    let imgMob2 = `${api}/${storageDirectory}/${img2}?alt=media`;
    let imgMob3 = `${api}/${storageDirectory}/${img3}?alt=media`;
    let imgMob4 = `${api}/${storageDirectory}/${img4}?alt=media`;
    let imgMob5 = `${api}/${storageDirectory}/${img5}?alt=media`;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event.callToActions.brand.background;
    let brandBgDesktop = `${api}/${storageDirectory}/${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/${storageDirectory}/${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event.callToActions.brand.product;
    let productDesktop = `${api}/${storageDirectory}/${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/${storageDirectory}/${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
      asideImage: asideImagePostCamp,
    } = event.postCampaign;
    let asideArtistImgPostCamp = `${api}/${storageDirectory}/${asideImagePostCamp}`;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnLinkPostCampaign: PostCampaignCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event.callToActions.brandSecondary;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpenModal(bool);
    };
    const confirmationScreen = useRef(null);
    const scrolltoSection = (elementRef) => {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    };
    useEffect(() => {
      if (registered) {
        scrolltoSection(confirmationScreen);
      }
    }, [registered]);

    console.log("regst", registered);
    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        postCampaign={postCampaign}
      >
        <Container>
          <HeroContent>
            <LogoLink
              onClick={() => {
                window.open("https://www.motel6.com/en/home.html", "_blank");
                linkClickHandler(
                  "https://www.motel6.com/en/home.html",
                  "Motel 6 Homepage",
                  "header"
                );
              }}
            />
            <img
              src={isMobile ? imgHeroMobile : imgHero}
              alt="abraham alexander in motel6"
            />
          </HeroContent>

          <Content>
            {!registered && !postCampaign ? (
              <ContainerPadding>
                <RegistrationForm
                  formPart={formStep}
                  formHeading={formHeading}
                  formLegal={formLegal}
                  submitButton={submit}
                  setIneligible={setIneligible}
                  setLoading={setLoading}
                />
              </ContainerPadding>
            ) : (
              <ContainerPadding post={true}>
                <Wrapper ref={confirmationScreen}>
                  {(heading || postCampaignHeading) && (
                    <Heading>
                      {postCampaign
                        ? ReactHtmlParser(postCampaignHeading)
                        : ReactHtmlParser(heading)}
                    </Heading>
                  )}
                  {(body || postCampaignBody) && (
                    <Body>
                      {postCampaign
                        ? ReactHtmlParser(postCampaignBody)
                        : ReactHtmlParser(body)}
                    </Body>
                  )}
                </Wrapper>
              </ContainerPadding>
            )}

            <AsideContent>
              {!registered && !postCampaign
                ? (asideArtistImg || asideArtistImgMobile) && (
                    <AsideImage
                      src={isMobile ? asideArtistImgMobile : asideArtistImg}
                      alt="abraham alexander playing guitar on dark background"
                    />
                  )
                : (asideArtistImgPostCamp || asideArtistImg) && (
                    <AsideImage
                      style={{ padding: isMobile ? "" : "7% 8% 7% 0" }}
                      src={
                        postCampaign ? asideArtistImgPostCamp : asideArtistImg
                      }
                      alt="abraham alexander playing guitar on dark background"
                    />
                  )}
            </AsideContent>
          </Content>

          {(registered || postCampaign) && (
            <BrandCTA>
              <ImageBox>
                <AsideImage src={isMobile ? imgCTAMobile : imgCTA} />
              </ImageBox>
              <ContentCTA>
                <Wrapper>
                  <Heading style={{ marginTop: isMobile ? "40px" : "20px" }}>
                    We’ll leave the light on. For music lovers.
                  </Heading>
                  <Body>
                    Motel 6 offers great low rates just about everywhere music
                    is played. With almost 1,500 locations, you’ll never miss a
                    beat.
                    <br />
                    <br />
                    Find great low rates on affordable and comfortable rooms for
                    your next adventure.
                  </Body>
                  <ButtonPrimary
                    as="a"
                    href={PostCampaignCTALink}
                    onClick={() =>
                      linkClickHandler(
                        PostCampaignCTALink,
                        "Book now button",
                        "external"
                      )
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book now
                  </ButtonPrimary>
                </Wrapper>
              </ContentCTA>
            </BrandCTA>
          )}
        </Container>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  max-width: 1440px;
  margin: 0 auto;
  flex-direction: column;
  background-color: #fff;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ContainerPadding = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2.5;
  padding: 30px 30px 60px 50px;
  align-items: flex-end;

  ${({ post }) =>
    post &&
    css`
      padding: 0px;
      align-items: flex-start;
    `}
  @media (max-width: 950px) {
    padding: 20px 20px 0;
    ${({ post }) =>
      post &&
      css`
        padding: 0px;
        margin-top: -30px;
      `}
  }
`;

const HeroContent = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 7% auto auto;
  align-items: flex-start;

  @media (max-width: 950px) {
    margin: 0 auto 25px;
    width: 90%;
  }

  & > a {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
const LogoLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 30%;
  cursor: pointer;
`;

const AsideContent = styled.div`
  display: flex;
  flex: 2;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;
const AsideImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0 auto auto;
`;
const BrandCTA = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 950px) {
    flex-direction: column-reverse;
    margin-bottom: 0px;
  }
`;
const ImageBox = styled.div`
  display: flex;
  flex: 2.5;
`;
const ContentCTA = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  // padding: 0 50px;
  align-items: flex-start;
  justify-content: center;
`;

export const Body = styled.p`
  font-size: min(1.2vw, 16px);

  span.headline {
    font-size: 30px;
    color: #0731a1;
    display: inline-block;
    margin: 25px 0 10px;
    font-family: "Avenir_Regular";
  }
  span.super {
    font-size: 12px;
    display: inline-block;
    margin: -10px 0 0 0;
    position: relative;
    top: -5px;
  }

  @media (max-width: 950px) {
    font-size: 0.9rem;
    display: block;
    width: 100%;
    margin-left: 0;
  }
`;

const LogoBox = styled.div`
  padding: 40px 0 45px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 950px) {
    margin-left: -40px;
  }
`;

const Link = styled.a`
  width: 100%;
  max-width: 300px;

  @media (max-width: 950px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  width: 70%;
  display: flex;

  @media (max-width: 950px) {
    width: 80%;
    margin: 0 auto;
  }
`;

export const Heading = styled.h2`
  font-size: min(4vw, 40px);
  margin-bottom: 0px;
  @media (max-width: 950px) {
    font-size: 40px;
    //  margin-top: 0;
  }
`;
const SubHeading = styled.h3`
  font-weight: 200;
`;

const HeadingPostCampaign = styled(Heading)`
  // padding-right: 40px;
`;
const BodyPostCampaign = styled(Body)`
  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const SubBody = styled.p`
  font-size: 0.65rem;

  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;

`;
