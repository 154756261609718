import React, { useContext } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { useMediaQuery } from "hooks";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  postCampaign,
  preCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;
  // brand cta background
  const {
    desktop: brandBgD,
    mobile: brandBgM,
  } = event.callToActions.brand.background;
  let brandBgDesktop = `${api}/${storageDirectory}/${brandBgD}?alt=media`;
  let brandBgMobile = `${api}/${storageDirectory}/${brandBgM}?alt=media`;

  const isMobile = useMediaQuery();
  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo,
  } = event.logos;

  let secondaryLogoImg = `${api}/${storageDirectory}/${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    maxPolicy,
    uscPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    grandPrizeDisclaimer,
  } = event.footer;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer isMobile={isMobile}>
            {brandDisclaimer && !preCampaign && (
              <BrandDisclaimer
                onClick={() =>
                  linkClickHandler(
                    "https://bit.ly/3NcIkPn",
                    "Footer Rules",
                    "footer"
                  )
                }
              >
                {ReactHtmlParser(brandDisclaimer)} &nbsp;
              </BrandDisclaimer>
            )}
            {maxPolicy && (
              <MaxPolicy
                onClick={() =>
                  linkClickHandler(
                    "https://www.max.live/privacy-policy",
                    "MAX Homepage/Privacy policy",
                    "footer"
                  )
                }
              >
                {ReactHtmlParser(maxPolicy)}{" "}
                <CookieButton
                  onClick={() => {
                    if (window.Cookiebot) {
                      window.Cookiebot.renew();
                    }
                  }}
                >
                  Click here to set Cookie Preferences.
                </CookieButton>
              </MaxPolicy>
            )}
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  z-index: 1;
  position: relative;

  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    max-width: 1600px;
    margin: -5px auto 0 auto;
    position: relative;
  }
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  padding: 35px 150px 35px 65px;
  background-color: #092a49;
  color: #000;
  z-index: 10;

  a {
    color: #ffffff;
  }
  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
      gap: 0px;
      padding: 30px;
      align-items: center;
    `}
`;
const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  text-decoration: underline;
  font-family: "Avenir_Regular";
  cursor: pointer;
  padding: 0;
`;
const Link = styled.a`
  margin: 15px;
  align-self: center;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0 0 0 50px;
    align-self: start;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 234px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: unset;
  }
`;

const SweepsDisclaimer = styled.div`
  margin-top: 0;
  font-size: 0.5rem;
`;

const MaxPolicy = styled.div`
  margin-top: 0;
  color: #ffffff;
  width: 100%;
  @media (max-width: 1050px) {
  }
`;

const BrandDisclaimer = styled.div`
  margin-bottom: 20px;
  color: #ffffff;
`;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: #fff;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;
const PatternMobile = styled.img`
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  z-index: 0;
`;
